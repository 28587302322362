<template>
  <div
    :class="{
      'product-recommend__status-feedback': true,
      'with-min-height': status === 'empty' || status === 'error-first',
      'with-bottom-padding': status === 'loading',
    }"
  >
    <CardListSkeleton
      v-if="status === 'holding'"
      :num="12"
      :column="2"
    />

    <s-empty-state-view
      v-else-if="status === 'empty'"
      :content="language.SHEIN_KEY_PWA_15819 || 'It is empty here :-('"
    />

    <s-loading
      v-else-if="status === 'loading'"
      :show="true"
    />

    <ErrorReloadPage
      v-else-if="status === 'error-first'"
      @reload="clickRetry"
    />

    <div
      v-else-if="status === 'error'"
      class="error-tips"
    >
      <p
        @click="clickRetry"
      >
        {{ language.SHEIN_KEY_PWA_18764 || 'System is busy, please click me and try again!' }}
      </p>
    </div>
  </div>
</template>

<script>
/**
 * 无vuex依赖的推荐位状态反馈组件
 */
import { defineComponent } from 'vue'
import { SEmptyStateView } from '@shein-aidc/sui-empty-state-view/mobile'
import { SLoading } from '@shein-aidc/sui-loading/mobile'
import CardListSkeleton from '../CardListSkeleton/index.vue'
import ErrorReloadPage from '../ErrorReloadPage/index.vue'

const { language } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

export default defineComponent({
  name: 'StatusFeedback',
  components: {
    SEmptyStateView,
    ErrorReloadPage,
    CardListSkeleton,
    SLoading,
  },
  emits: [
    'retry',
  ],
  props: {
    /**
     * 状态
     * * 'holding' - 首次未加载，展示占位
     * * 'loading' - 加载中
     * * 'empty' - 空
     * * 'error' - 加载失败
     */
    status: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      language,
    }
  },
  methods: {
    // 重试加载
    clickRetry() {
      this.$emit('retry')
    },
  },
})
</script>

<style lang="less" scoped>
.product-recommend__status-feedback {
  margin-bottom: 0.2667rem;

  &.with-min-height {
    min-height: 6.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.with-bottom-padding {
    padding-bottom: .026667rem;
  }

  .error-tips {
    color: #999;
    text-align: center;
    line-height: .8533rem;
  }
}
</style>
