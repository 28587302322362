<template>
  <div
    ref="pureList"
    :class="[
      'product-recommend__pure-list',
      'j-da-event-box',
    ]"
    data-floor-tab="recommendPureList"
    v-bind="renderBindData"
  >
    <div
      class="event-report-box"
      :da-expose-code="componentsCode"
    >
      <component
        :is="isUseNewWaterFall ? 'WaterFallV2' : 'WaterFall'"
        v-if="!hideWaterfall"
        :items="products"
        :dynamicItems="dynamicItems"
        :deleteItemsFlag="deleteItemsFlag"
        @update:deleteItemsFlag="updateProductsDeleteFlag"
      >
        <template #default="{ item, index: itemIndex }">
          <template v-if="item._slotType">
            <slot
              :name="item._slotType"
              :item="item"
              :index="itemIndex"
            ></slot>
          </template>
          <MultipleImageRowCard
            v-else
            :key="`${item.goods_id}_${itemIndex}`"
            :ref="`goodsItem_${item.goods_id}`"
            :language="itemLanguage"
            :constant-data="constantData"
            :item="item"
            :index="itemIndex"
            :config="isYmalTab && item.userBehaviorLabel ? colorfulStyleConfig : productItemsConfig"
            :cur-operate-index="feedbackIndex"
            class="recommend__goods-item"
            :class="{
              'recommend__colorful-style': isYmalTab && item.userBehaviorLabel,
            }"
            style="margin-bottom: 0.16rem;"
            @clickItem="clickItem"
            @openQuickAdd="openQuickAdd"
            @longClick="handleFeedbackIndex"
          >
            <template v-if="isYmalTab && item.userBehaviorLabel?.pictureBeltText" #imgBottomInTop>
              <div class="product-recommend__picture-belt-text">
                {{ item.userBehaviorLabel?.pictureBeltText || '' }}
              </div>
            </template>

            <!-- 需要判断三种类型来使用其中一种组件 -->
            <template 
              v-if="isYmalTab && getAppTraceInfo(item) === 'reduction_1'"
              #cardPriceTop
            >
              <priceDropBehavior 
                :user-behavior-labels="{
                  labelLang: getLabelLang(item),
                  description: item.userBehaviorLabel?.description || ''
                }" 
                :scroll-page="isScrolled"
              />
            </template>
            <template 
              v-else-if="isYmalTab && getAppTraceInfo(item)  === 'almostsoldout_1'"
              #cardPriceTop 
            >
              <lowStockBehavior 
                :user-behavior-labels="{
                  labelLang: getLabelLang(item),
                }" 
              />
            </template>
            <template 
              v-else-if="isYmalTab && getAppTraceInfo(item) === 'discount_1'"
              #cardPriceTop 
            >
              <followDiscountBehavior
                :user-behavior-labels="{
                  labelLang: getLabelLang(item),
                }" 
              />
            </template>
          </MultipleImageRowCard>
        </template>

        <template #dynamic="{ item, index: itemIndex, insertedItemIndex }">
          <template v-if="item._slotType">
            <slot
              :name="item._slotType"
              :item="item"
              :index="itemIndex"
              :insertedItemIndex="insertedItemIndex"
            ></slot>
          </template>
        </template>
      </component>

      <slot name="bottom"></slot>
    </div>
  </div>
</template>

<script>
/**
 * 无vuex依赖的独立推荐列表模块
 * * HACK-透过此组件控制 WaterFall 等性能消耗很大的组件的渲染时机
 */

import { defineComponent, nextTick } from 'vue'
import WaterFall from 'public/src/pages/components/product/WaterFall'
import WaterFallV2 from 'public/src/pages/components/product/waterFall_v2/WaterFall_v2.vue' // 新增动态插坑功能
import MultipleImageRowCard from 'public/src/pages/components/product/item_v3/MultipleImageRowCard.vue'
import lowStockBehavior from 'public/src/pages/components/product/carrier_ui/BehaviorProductSet/components/lowStockBehavior.vue'
import priceDropBehavior from 'public/src/pages/components/product/carrier_ui/BehaviorProductSet/components/priceDropBehavior.vue'
import followDiscountBehavior from 'public/src/pages/components/product/carrier_ui/BehaviorProductSet/components/followDiscountBehavior.vue'
import useItemMixin from 'public/src/pages/components/product/item_v2/js/mixin.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
const daEventExpose = daEventCenter.getExposeInstance()

export default defineComponent({
  name: 'PureList',
  components: {
    WaterFall,
    WaterFallV2,
    MultipleImageRowCard,
    lowStockBehavior,
    priceDropBehavior,
    followDiscountBehavior,
  },
  mixins: [
    useItemMixin,
  ],
  emits: [
    'click-item',
    'open-quick-add',
    'load-more',
    'open-gtl-popup'
  ],
  props: {
    // 数据源
    products: {
      type: Array,
      default: () => [],
    },
    // 距离底部间距加载下一页
    loadSpace: {
      type: Number,
      default: 0,
    },
    // 关闭scroll方法执行，多个listController时使用
    closeScrollHandler: {
      type: Boolean,
      default: false,
    },
    // 商品项配置
    productItemsConfig: {
      type: Object,
      default: () => ({}),
    },
    // 盒子data属性，用于曝光埋点配置
    boxBindData: {
      type: Object,
      default: () => ({}),
    },
    // 隐藏瀑布流组件，因渲染性能问题，瀑布流组件可从外部控制延期渲染
    hideWaterfall: {
      type: Boolean,
      default: false,
    },
    /**
     * 商品项多语言
     */
    itemLanguage: {
      type: Object,
      default: () => {},
    },
    /**
     * 是否使用页面滚动，防止低版本获取滚动parent错误问题
     */
    isUsePageScroll: {
      type: Boolean,
      default: false,
    },
    isUseNewWaterFall: {
      type: Boolean,
      default: false,
    },
    dynamicItems: {
      type: Array,
      default: () => []
    },
    deleteItemsFlag: {
      type: Boolean,
      default: false,
    },
    isYmalTab: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      scrollParent: null,
      scrollHandler: null,
      // 滚动冒泡锁
      holding: false,
      holdingTimer: -1,
      feedbackIndex: -1, // 负反馈用
      isScrolled: false
    }
  },
  computed: {
    colorfulStyleConfig(){
      return {
        showTitle: true,
        showAddBagBtn: true,
        showSheinClubPriceOnSale: true,
        showS3VipPriceOnSale: true,
        showNewFlashPriceOnSale: true,
        showEstimatedPriceOnSale: true,
        showEstimatedLabelCountdown: true,
        showNewFlashNewLabel: true,
        showNewuseronlyPriceLabel: true,
        showFrDeRrpPrice: true,
        showSalesLabel: true,
        showSeriesBrand: true,
        showLocateLabels: true,
      }
    },
    // 组件曝光标识
    componentsCode() {
      return `pure_rec_list_${ new Date().getTime() }`
    },
    // 盒子属性
    renderBindData() {
      const { boxBindData = {} } = this
      const bindData = {}
      for (let key in boxBindData) {
        bindData[`data-${key}`] = boxBindData[key]
      }
      return bindData
    },
  },
  watch: {
    products: {
      handler(list) {
        if (!list.length) return
        // HACK 未知tick数量，必须借由计时器
        setTimeout(() => {
          this.refreshProductInfo(list)
        }, 10)
      },
      immediate: true
    },
  },
  mounted() {
    // 重制，曝光
    daEventExpose.reset(this.componentsCode)
    // 滚动事件绑定
    const scrollParent = this.getScrollParent(this.$refs?.pureList)
    if (!scrollParent) return
    // 页面滚动特殊处理
    let scrollPageFlag = false
    if (
      scrollParent === document.body
      || scrollParent === document.documentElement
    ) {
      scrollPageFlag = true
    }
    let timer = null // 判断页面是否滚动
    // 滚动事件句柄
    this.scrollHandler = () => {
      this.isScrolled = true
        clearTimeout(timer)
        timer = window.setTimeout(() => {
          this.isScrolled = false
      }, 100)
      if (this.closeScrollHandler) return
      const { scrollHeight, clientHeight } = scrollParent
      const currentScrollTop = scrollPageFlag
        ? document.documentElement.scrollTop || document.body.scrollTop
        : scrollParent.scrollTop
      const currentViewBottom = currentScrollTop + clientHeight
      const space = this.loadSpace || (clientHeight / 3 * 2)
      if (currentViewBottom > (scrollHeight - space) && this.products.length) {
        // 减少上报频次
        if (this.holding) return
        this.holdingTimer = setTimeout(() => {
          this.holding = false
        }, 1e3)
        this.holding = true
        this.$emit('load-more')
      }
    }

    // 绑定事件
    const eventTarget = scrollPageFlag ? window : scrollParent
    eventTarget.addEventListener('scroll', this.scrollHandler)
    this.scrollParent = eventTarget
  },
  beforeUnmount() {
    // 注销事件
    daEventExpose?.remove?.(this.componentsCode)
    this.scrollParent?.removeEventListener('scroll', this.scrollHandler)
    clearTimeout(this.holdingTimer)
  },
  methods: {
    getLabelLang(item){
      return item.userBehaviorLabel?.labelLang || ''
    },
    getAppTraceInfo(item){
      return item.userBehaviorLabel?.appTraceInfo || ''
    },
    // 刷新商品原子数据
    async refreshProductInfo(products) {
      const list = products.filter(d => !!d.goods_id)
      if (!list?.length) return

      // 因价格等商品信息动态获取，故在数据获取后更新item的dom上的数据
      nextTick(() => {
        list.forEach(product => {
          const goodsId = product.goods_id
          const itemInstance = this.$refs?.[`goodsItem_${goodsId}`]
          itemInstance?.setElAttr?.()
        })

        this.updateListExpose()
      })
    },
    // 获取滚动父级
    getScrollParent(node) {
      if (node == null) {
        return null
      }

      const _isHasScroll = (node) => {
        return node.scrollHeight > node.clientHeight
      }
      if (this?.isUsePageScroll) {
        return _isHasScroll(document.body) ? document.body : document.documentElement
      }

      if (_isHasScroll(node)) {
        return node
      } else {
        return this.getScrollParent(node.parentNode)
      }
    },
    // 点击单项
    clickItem(payload) {
      this.$emit('click-item', payload)
    },
    // 点击单项加车
    openQuickAdd(payload) {
      this.$emit('open-quick-add', payload)
    },
    // 滚动元素到视图内
    // * HACK 基于渲染的商品元素类名 recommend__goods-item
    // * 类名更换需同步修改
    scrollToItem(goodsId, { smooth = true } = {}) {
      if (!goodsId) return
      const container = this.$refs?.pureList
      if (!container) return

      // 延时滚动
      setTimeout(
        () => {
          const target = container.querySelector(`.recommend__goods-item[data-id="${goodsId}"]`)
          if (!target) return
          target.scrollIntoView({
            behavior: smooth ? 'smooth' : 'auto',
            block: smooth ? 'center' : 'end',
            inline: 'center'
          })
        },
        smooth ? 3e2 : 0
      )
    },
    handleFeedbackIndex({ index, isShow }) {
      if(!isShow) {
        return 
      }
      this.feedbackIndex = index
    },

    // 更新曝光
    updateListExpose() {
      const { productItemsConfig } = this
      const { itemDAEventExposeId = '2-3-2' } = productItemsConfig || {}
      const keycode = `${this.componentsCode}\`${itemDAEventExposeId}`
      // 曝光埋点
      if (itemDAEventExposeId && daEventExpose) {
        // 曝光回调
        const { exposeCallback } = productItemsConfig || {}
        setTimeout(() => {
          daEventExpose.subscribe({
            keycode,
            type: 'list',
            hooks: {
              afterExpose: ({ targets }) => {
                exposeCallback?.({ targets })
              }
            }
          })
        }, 200)
      }
    },
    updateProductsDeleteFlag(value) {
      this.$emit('update:deleteItemsFlag', value)
    },
  },
})
</script>

<style lang="less">
.product-recommend__pure-list {
  padding: 0 0.16rem !important; /* stylelint-disable-line declaration-no-important */
  margin-top: 0.16rem;

  // *覆盖样式，类名更换需同步修改
  .product-item-ctn {
    margin-bottom: .4267rem;

    .product-item__ccctsp-label {
      max-width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
.product-recommend__picture-belt-text{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 10px;
  height: 20px;
  background: rgba(0, 0, 0, 0.60);
  padding: 4px;
  color:#FFFFFF;
  width: 100%;
  line-height: 1;
  text-align: center
}
.recommend__colorful-style{
  .product-card-upperright{
    display: none;
  }
}
</style>
