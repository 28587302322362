<template>
  <PureList
    ref="ListRef"
    :products="products"
    :product-items-config="productItemsConfig"
    :box-bind-data="boxBindData"
    :item-language="itemLanguage"
    :load-space="loadSpace"
    :close-scroll-handler="closeScrollHandler"
    :isUsePageScroll="isUsePageScroll"
    :isUseNewWaterFall="isUseNewWaterFall"
    :dynamicItems="dynamicItems"
    :deleteItemsFlag="deleteItemsFlag"
    :is-ymal-tab="isYmalTab"
    @load-more="loadNextPage"
    @click-item="clickItem"
    @open-quick-add="openQuickAdd"
    @open-gtl-popup="openGtlPopup"
    @update:deleteItemsFlag="updateProductsDeleteFlag"
  >
    <!-- vue3 取消了作用域插槽 $scopedSlots todo_upt -->
    <template
      v-for="(index, name) in $slots"
      #[name]="data"
    >
      <slot
        :name="name"
        v-bind="data"
      ></slot>
    </template>
    <template #bottom>
      <StatusFeedback
        :status="cDataStatus"
        @retry="retryLoadData"
      />
    </template>
  </PureList>
</template>

<script>
import { defineComponent } from 'vue'
/**
 * 无vuex依赖的独立推荐列表数据处理模块
 * * 处理数据加载、翻页、状态反馈等逻辑
 */
export default defineComponent({
  name: 'ListDataController',
})
</script>
<script setup>
import { ref, computed } from 'vue'
import PureList from './PureList'
import StatusFeedback from './StatusFeedback'

const props = defineProps({
  products: {
    type: Array,
    default: () => [],
  },
  productItemsConfig: {
    type: Object,
    default: () => ({}),
  },
  boxBindData: {
    type: Object,
    default: () => ({}),
  },
  startPage: {
    type: Number,
    default: 1,
  },
  loadSpace: {
    type: Number,
    default: 0,
  },
  closeScrollHandler: {
    type: Boolean,
    default: false,
  },
  loadData: {
    type: Function,
    default: () => {},
  },
  itemLanguage: {
    type: Object,
    default: () => ({}),
  },
  isUsePageScroll: {
    type: Boolean,
    default: false,
  },
  isUseNewWaterFall: {
    type: Boolean,
    default: false,
  },
  /**
   * 动态插入瀑布流商品
   * triggerGoodsId 与 targetSlotIndex 二选一
   * 格式:
   * [
   *  {
   *    triggerGoodsId: '' // 商品id
   *    targetSlotIndex: 0 // 目标插入的位置
   *    insertItems: [] // 插入的商品
   *  },
   * ]
   */
  dynamicItems: {
    type: Array,
    default: () => []
  },
  deleteItemsFlag: {
    type: Boolean,
    default: false,
  },
  needAddPage: {
    type: [Number, Boolean],
    default: false,
  },
  needResetPage: {
    type: [Number, Boolean],
    default: false,
  },
  isYmalTab: {
    type: Boolean,
    default: false,
  }
})

const emit = defineEmits(['click-item', 'open-quick-add', 'open-gtl-popup', 'update:deleteItemsFlag'])

defineExpose({
  initPage,
  scrollToItem,
  updateListExpose,
})

const currentPage = ref(-1)
const dataLoading = ref(false)
const loadError = ref(false)
const listLoaded = ref(false)
const ListRef = ref(null)
const isAddPageDone = ref(false)

const cDataStatus = computed(() => {
  const isFirstLoaded = !cHasLoadSuccess.value && !props.products?.length

  if (isFirstLoaded && (!loadError.value || dataLoading.value)) {
    return 'holding'
  }
  if (dataLoading.value) {
    return 'loading'
  }
  if (loadError.value) {
    return isFirstLoaded ? 'error-first' : 'error'
  }
  if (!props.products?.length) {
    return 'empty'
  }
  return 'default'
})

const cHasLoadSuccess = computed(() => {
  return currentPage.value !== -1
})

async function initPage() {
  if (cHasLoadSuccess.value) return
  return loadNextPage()
}

async function loadNextPage(){
  if (
    !(props.loadData instanceof Function) || 
    dataLoading.value || 
    listLoaded.value || 
    loadError.value
  ) {
    return
  }

  dataLoading.value = true
  loadError.value = false
  let stagingPage = cHasLoadSuccess.value ? currentPage.value : props.startPage
  if (props.needAddPage && !isAddPageDone.value) {
    stagingPage = props.needAddPage + 1
    isAddPageDone.value = true
  }
  if (props.needResetPage && !isAddPageDone.value) {
    stagingPage = 1
    isAddPageDone.value = true
  }
  
  try {
    const hasNextPage = await props.loadData(stagingPage)
    if (!hasNextPage) {
      // eslint-disable-next-line require-atomic-updates
      listLoaded.value = true
    }
    setTimeout(() => {
      currentPage.value = stagingPage + 1
      dataLoading.value = false
    }, 5e2)
  } catch (err) {
    // eslint-disable-next-line require-atomic-updates
    loadError.value = true
    setTimeout(() => {
      dataLoading.value = false
    }, 3e3)
  }
}

function retryLoadData() {
  if (!loadError.value) return
  loadError.value = false
  loadNextPage()
}

function clickItem(payload) {
  emit('click-item', payload)
}

function openQuickAdd(payload) {
  emit('open-quick-add', payload)
}

function openGtlPopup(payload) {
  emit('open-gtl-popup', payload)
}


/**
 * 滚动到指定商品
 */
function scrollToItem(goodsId, opt) {
  ListRef.value?.scrollToItem(goodsId, opt)
}

function updateListExpose() {
  ListRef.value?.updateListExpose?.()
}

function updateProductsDeleteFlag(value) {
  emit('update:deleteItemsFlag', value)
}

</script>
