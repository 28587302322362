<template>
  <s-empty-state-view class="recommend__empty-record-list">
    <template #icon>
      <img
        class="recommend__empty-record-icon"
        src="https://img.ltwebstatic.com/images3_ccc/2024/08/20/bf/1724144715de6b57789fec0ca8dc267d1017b4f3fb.png"
        alt="error_img"
      />
    </template>
    <template #content>
      <div>
        <p class="recommend__empty-record-sub">
          {{ language.SHEIN_KEY_PWA_21416 || 'OOPS!' }}
        </p>
        <p class="recommend__empty-record-desc">
          {{ language.SHEIN_KEY_PWA_18579 || 'There is something wrong with our server. We are trying to fix it. Please try again later.' }}
        </p>
        <p class="recommend__empty-record-btn">
          <s-button
            :type="['primary', 'H72PX']"
            :width="4"
            block
            @click="reRequest"
          >
            {{ language.SHEIN_KEY_PWA_18128 || 'TRY IT AGAIN' }}
          </s-button>
        </p>
      </div>
    </template>
  </s-empty-state-view>
</template>
<script>
import { defineComponent } from 'vue'
import { SEmptyStateView } from '@shein-aidc/sui-empty-state-view/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
const { language } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

export default defineComponent({
  name: 'ErrorPage',
  components: {
    SEmptyStateView,
    SButton,
  },
  data () {
    return {
      language,
    }
  },
  methods: {
    reRequest () {
      this.$emit('reload')
    }
  },
})
</script>
  <style lang="less" scoped>
    .recommend__empty-record-list{
      padding: 200/75rem 0;
      .recommend__empty-record-icon{
        width: 174/75rem;
        height: 139/75rem;
        margin-bottom: 0.64rem;
        border: none;
      }
      .recommend__empty-record-sub{
        margin-bottom: 0.32rem;
        font-family: 'SF UI Text';
        font-style: normal;
        font-weight: 700;
        .font-dpr(28px);
        line-height: 17px;
        text-transform: uppercase;
        letter-spacing: 0.003px;
        color: #222;
        text-align: center;
      }
      .recommend__empty-record-desc{
        margin-bottom: 80/75rem;
        font-family: 'SF UI Display';
        font-style: normal;
        font-weight: 400;
        .font-dpr(24px);
        line-height: 17px;
        color: #222;
        text-align: center;
        padding: 0 .32rem;
      }
      .recommend__empty-record-btn{
        display: flex;
        justify-content: center;
      }
    }
  </style>
  
