<template>
  <PlaceholderItem
    :type="''"
    :num="num"
    :column="column"
  />
</template>
<script>
import { defineComponent } from 'vue'
import PlaceholderItem from 'public/src/pages/components/ccc/base/PlaceholderItem'
export default defineComponent({
  name: 'CardListPlaceholder',
  components: {
    PlaceholderItem
  },
  props: {
    column: {
      type: Number,
      default: 2
    },
    num: {
      type: Number,
      default: 12
    },
  },
})
</script>
